<template>
  <div class="left_subject_controller_warp">
    <div class="left_controller">
      <div class="fixed_wrap">
        <img src="@/assets/leftcontroller/返回.png"
             class="fixed_back_btn"
             @click="back"
             alt="">
      </div>
      <div class="wrap">
        <div :class="{'item':true,'active':item.subject_id == subjectId}"
             v-for="item in list"
             @click="checkSubject(item.subject_id)"
             :key="item.subject_id">
          <img :src="require(`@/assets/leftcontroller/icon-${item.subject_name}-${item.subject_id == subjectId?'press':'normal'}@2x.png`)"
               alt="">
          <span>{{item.subject_name}}</span>
          <!-- <template v-if='item.subject_id!=13'>
            <img :src="require(`@/assets/leftcontroller/icon-${item.subject_name}-${item.subject_id == subjectId?'press':'normal'}@2x.png`)"
               alt="">
            <span>{{item.subject_name}}</span>
          </template> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStudentSubject } from '@/api/subject.js'
import { mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {
      subjectList: [],
      subjectId: '',
      isOnlyOne:false
    }
  },
  props: {
    delYY: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.initData()

  },
  computed: {
    // ...mapState(['subjectList']),
    list () {
      if (!this.delYY) {
        return this.subjectList;
      }

      return this.subjectList.filter(item => {
        if (item.subject_id != 7 && item.subject_id != 11) {
          return item
        }
      })
    }
  },
  methods: {
    // ...mapMutations(['SET_SUBJECT']),
    back () {
      this.$router.go(-1)
    },
    async initData () {
      // if (!this.subjectList.length) {
      const { data } = await getStudentSubject()
      var index = data.list.findIndex(e => e.subject_id == 13)
      if (index > 0) {
        data.list.splice(index, 1)
      }
      // this.SET_SUBJECT(data.list)
      // }
      this.subjectList = data.list
      if (window.sessionStorage.getItem('checkSubjectId')) {
        if (!this.delYY) {
          this.checkSubject(window.sessionStorage.getItem('checkSubjectId'))
        }
        else {
          let subjectIdFlag = window.sessionStorage.getItem('checkSubjectId')

          if (subjectIdFlag != 7 && subjectIdFlag != 11) {

            this.checkSubject(window.sessionStorage.getItem('checkSubjectId'))
          } else {

            this.subjectId = this.list[0].subject_id
            this.getData()
          }
        }

      } else {
        this.subjectId = this.list[0].subject_id
        this.isOnlyOne = true
        this.getData()
      }

    },
    checkSubject (id) {
      window.sessionStorage.setItem('checkSubjectId', id)
      if (this.subjectId == id) return
      this.subjectId = id
     this.subjectList.map(item=>{
      if(item.subject_id == this.subjectId ){
        this.isOnlyOne =item.isOnlyOne
      }
     })
      this.getData()
    },
    getData () {
      this.$emit('initList', this.subjectId)
    }
  }
}
</script>

<style lang="scss" scoped>
.left_subject_controller_warp {
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  box-shadow: 0rem 2rem 8rem 2rem rgba(0, 0, 0, 0.1);

  .left_controller {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 100rem;
    height: 100vh;
    // box-shadow: 0rem 2rem 8rem 2rem rgba(0, 0, 0, 0.1);
    background: #ffffff;
    .fixed_wrap {
      width: 50rem;
      height: 50rem;
      margin-top: 15rem;

      .fixed_back_btn {
        cursor: pointer;
        top: 15rem;
        left: 25rem;
        width: 50rem;
      }
    }
    .wrap {
      height: calc(100vh - 70rem);
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      overflow: auto;
      scrollbar-width: none;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20rem;
        line-height: 33rem;
        margin-top: 30rem;
        cursor: pointer;
        img {
          width: 41rem;
          height: 44rem;
          margin-bottom: 10rem;
        }
      }
    }
  }
  .right_view {
    flex: 1;
    width: calc(100vw - 100rem);
    height: 100vh;
    overflow-y: scroll;
    background: #f6f6f6;
  }
}
.active {
  color: #2196f3 !important;
}
</style>